import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { IFeature } from 'src/app/interfaces/feature';
import { IMenuItem } from 'src/app/interfaces/menu-item';
import { ITeamItem } from 'src/app/interfaces/team-item';
import { TeamService } from 'src/app/services/team.service';

@Component({
  selector: 'app-front',
  templateUrl: './front.component.html',
  styleUrls: ['./front.component.scss']
})
export class FrontComponent {
  people: ITeamItem[] = [];
  menu: IMenuItem[] = [];
  features: IFeature[] = [
    {
      name: 'Website design and development',
      faIcon: 'fa-code',
      description: 'Creating a website from scratch or redesigning an existing one.'
    },
    {
      name: 'Web application development',
      faIcon: 'fa-desktop',
      description: 'Creating custom web applications, such as e-commerce platforms, content management systems, and social networking sites.'
    },
    {
      name: 'Mobile app development',
      faIcon: 'fa-mobile',
      description: 'Creating native or hybrid mobile apps for iOS and Android devices.'
    },
    {
      name: 'Website maintenance and support',
      faIcon: 'fa-cog',
      description: 'Keeping a website up and running and resolving any technical issues.'
    }
  ];
  constructor(
    private teamService: TeamService,
    private meta: Meta
  ) {}
  ngOnInit(): void {
    this.people = this.teamService.getTeam();
    this.menu = this.getCustomMenu();

  }
  getCustomMenu(): IMenuItem[] {
    return [
      {
        name: 'Welcome',
        href: '#intro',
        isActive: true
      },{
        name: 'Who we are',
        href: '#one',
        isActive: false
      },{
        name: 'What we do',
        href: '#two',
        isActive: false
      },{
        name: 'Get in touch',
        href: '#three',
        isActive: false
      },
    ];
  }
}
