import { Component, Input } from '@angular/core';
import { IContact } from 'src/app/interfaces/contact';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent {
  @Input() contact: IContact = <IContact>{};
}
