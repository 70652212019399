<section id="sidebar">
  <div class="inner">
    <app-menu [menu]="menu"></app-menu>
  </div>
</section>
<div id="wrapper">
  <!-- Intro -->
  <app-intro></app-intro>

  <!-- One -->
  <section id="one" class="wrapper style2 spotlights anchor_tags" href="#one">
    <app-team-item *ngFor="let item of people" [item]="item"></app-team-item>
  </section>

  <!-- Two -->
  <section id="two" class="wrapper style3 fade-up anchor_tags">
    <div class="inner">
      <h2>What we do</h2>
      <p>We are a web development studio that specializes in creating custom websites, web applications, and mobile
        apps.
        We also offer website maintenance, and support services. We work with businesses of all sizes to help them
        achieve
        their online goals. Our team of experienced developers and designers is committed to providing our clients with
        the highest quality of service.
      </p>
      <div class="features">
        <section *ngFor="let feature of features">
          <app-feature [feature]="feature"></app-feature>
        </section>
      </div>
    </div>
  </section>

  <!-- Three -->
  <section id="three" class="wrapper style1 fade-up anchor_tags">
    <div class="inner">
      <app-feedback></app-feedback>
    </div>
  </section>
</div>
