import { Component, Input } from '@angular/core';
import { ITeamItem } from 'src/app/interfaces/team-item';

@Component({
  selector: 'app-mate-content',
  templateUrl: './mate-content.component.html',
  styleUrls: ['./mate-content.component.scss']
})
export class MateContentComponent {
  @Input() mate: ITeamItem = <ITeamItem>{};
}
