import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './modules/shared/menu/menu.component';
import { FrontComponent } from './content/front/front.component';
import { FooterComponent } from './content/footer/footer.component';
import { IntroComponent } from './content/intro/intro.component';
import { TeamItemComponent } from './content/team-item/team-item.component';
import { FeatureComponent } from './modules/shared/feature/feature.component';
import { FeedbackComponent } from './content/feedback/feedback.component';
import { FormsModule } from '@angular/forms';

import { TeamModule } from './modules/team/team.module';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './modules/shared/shared.module';
@NgModule({
  declarations: [
    AppComponent,
    FrontComponent,
    FooterComponent,
    IntroComponent,
    TeamItemComponent,
    FeedbackComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    TeamModule,
    HttpClientModule,
    SharedModule
  ],
  providers: [ provideClientHydration() ],  // add this line
  bootstrap: [AppComponent]
})
export class AppModule { }
