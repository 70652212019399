import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TeamRoutingModule } from './team-routing.module';
import { MateComponent } from './mate/mate.component';
import { MateSidebarComponent } from './mate-sidebar/mate-sidebar.component';
import { MateContentComponent } from './mate-content/mate-content.component';
import { MatePhotoComponent } from './mate-photo/mate-photo.component';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [
    MateComponent,
    MateSidebarComponent,
    MateContentComponent,
    MatePhotoComponent
  ],
  imports: [
    CommonModule,
    TeamRoutingModule,
    SharedModule
  ]
})
export class TeamModule { }
