<section *ngIf="contact.displayName">
  <ul class="contact">
    <li>
      <h3>Address</h3>
      <span>
        {{contact.location}}
      </span>
    </li>
    <li *ngIf="contact.email">
      <h3>Email</h3>
      <a href="mailto:{{contact.email}}">{{contact.email}}</a>
    </li>
    <li>
      <h3>Phone</h3>
      <span>{{contact.phone}}</span>
    </li>
    <li *ngIf="contact.socials">
      <h3>Social</h3>
      <ul class="icons">
        <li *ngFor="let item of contact.socials"><a href="{{item.url}}" class="icon brands {{item.faIcon}}"><span class="label">{{item.name}}</span></a></li>
      </ul>
    </li>
  </ul>
</section>
