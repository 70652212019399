import { Component, Input } from '@angular/core';
import { ITeamItem } from 'src/app/interfaces/team-item';

@Component({
  selector: 'app-mate-photo',
  templateUrl: './mate-photo.component.html',
  styleUrls: ['./mate-photo.component.scss']
})
export class MatePhotoComponent {
  @Input() mate: ITeamItem = <ITeamItem>{};
}
