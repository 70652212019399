<nav>
  <ul>
    <li *ngFor="let item of menu">
      <ng-container *ngIf="item.isActive == true; else elseTemplate">
        <a href="{{getHref(item.href)}}" class="scrolly active" >{{item.name}}</a>
      </ng-container>
      <ng-template #elseTemplate>
        <a href="{{getHref(item.href)}}" class="scrolly" >{{item.name}}</a>
      </ng-template>
    </li>
  </ul>
</nav>
