import { Component, Input } from '@angular/core';
import { MenuTypes } from 'src/app/helpers/menu-types.enum';
import { IMenuItem } from 'src/app/interfaces/menu-item';
import { ITeamItem } from 'src/app/interfaces/team-item';

@Component({
  selector: 'app-mate-sidebar',
  templateUrl: './mate-sidebar.component.html',
  styleUrls: ['./mate-sidebar.component.scss']
})
export class MateSidebarComponent {
  @Input() mate: ITeamItem = <ITeamItem>{};
  menu: IMenuItem[] = [];
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.menu = this.getCustomMenu()
  }
  getCustomMenu() {
    return [
      {
        name: 'Hello',
        href: `#intro`,
        isActive: false
      },{
        name: 'What I can',
        href: `#one`,
        isActive: false
      },{
        name: 'What I did',
        href: `#two`,
        isActive: false
      },{
        name: 'Contacts',
        href: `#three`,
        isActive: false
      },
    ];
  }
}
