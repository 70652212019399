import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ITeamItem } from 'src/app/interfaces/team-item';

@Component({
  selector: 'app-team-item',
  templateUrl: './team-item.component.html',
  styleUrls: ['./team-item.component.scss']
})
export class TeamItemComponent {
  @Input() item: ITeamItem = <ITeamItem>{};
  constructor(private router: Router) {}
  navigate(item: ITeamItem): void {
    this.router.navigate(['mate', item.name],  { fragment: '' });
  }
}
