<div *ngIf="feature.faIcon">
  <span  class="icon solid major {{feature.faIcon}}"></span>
</div>
<div *ngIf="feature.logoUrl" class="logo-card">
  <img src="{{feature.logoUrl}}">
  <div>
    <h3>{{feature.name}}</h3>
    <p [innerHTML]="feature.description"></p>
    <div style="display: none;">{{feature.description}}</div>
  </div>
</div>
