<section>
  <a href="#" class="image" ><img src="{{item.imageUrl}}" alt="{{item.name}} image" data-position="center center" /></a>
  <div class="content">
    <div class="inner">
      <h2>{{item.name}}</h2>
      <p [innerHTML]="item.description"></p>
      <ul class="actions">
        <li><a class="button" href="/mate/{{item.name}}">Learn more</a></li>
      </ul>
    </div>
  </div>
</section>
