import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureComponent } from './feature/feature.component';
import { MenuComponent } from './menu/menu.component';
import { ProjectComponent } from './project/project.component';
import { ContactsComponent } from './contacts/contacts.component';



@NgModule({
  declarations: [
    FeatureComponent,
    MenuComponent,
    ProjectComponent,
    ContactsComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FeatureComponent,
    MenuComponent,
    ProjectComponent,
    ContactsComponent
  ]
})
export class SharedModule { }
