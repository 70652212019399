<h2>Get in touch</h2>
<p>If you have anything to say, <br> please, feel free to contact us</p>
<div class="split style1">
  <section>
    <form method="post" action="#">
      <div class="fields">
        <div class="field half">
          <label for="name">Name
            <span *ngIf="name.invalid && (name.dirty || name.touched)" class="text-danger">

              <span *ngIf="name.errors?.['required']">
                is required.
              </span>
              <span *ngIf="name.errors?.['minlength']">
                must be at least 3 characters long.
              </span>
            </span>
          </label>
          <input type="text" name="name" id="name" class="form-control" required minlength="3"
            [(ngModel)]="feedback.name" #name="ngModel" autocomplete="off" />

        </div>
        <div class="field half">
          <label for="email">Email

            <span *ngIf="(prmEmail.invalid && prmEmail.touched) || prmEmail.dirty">
              <small *ngIf="prmEmail.errors?.['required']" class="text-danger">is required</small>
              <small *ngIf="prmEmail.errors?.['email']" class="text-danger">invalid email address</small>
            </span>
          </label>
          <input type="text" name="email" id="email" [email]="validateEmail" [(ngModel)]="feedback.email" required email="true" #prmEmail="ngModel" autocomplete="off" />
        </div>
        <div class="field">
          <label for="message">Message</label>
          <textarea name="message" id="message" rows="5" [(ngModel)]="feedback.description"></textarea>
        </div>
      </div>
      <ul class="actions">

        <li><button [disabled]="prmEmail.errors" href="#" class="button" (click)="send()">Send</button></li>
      </ul>
    </form>
  </section>
  <section>
    <ul class="contact">
      <li>
        <h3>Address</h3>
        <span>
          Ukraine, Poltava
        </span>
      </li>
      <li>
        <h3>Email</h3>
        <a href="mailto:yakogo.kh.ua@gmail.com">yakogo.kh.ua@gmail.com</a>
      </li>
      <li>
        <h3>Phone</h3>
        <span>+38(050) 506-9324</span>
      </li>
      <li>
        <h3>Social</h3>
        <ul class="icons">
          <li><a href="#" class="icon brands fa-twitter"><span class="label">Twitter</span></a></li>
          <li><a href="#" class="icon brands fa-facebook-f"><span class="label">Facebook</span></a></li>
          <li><a href="#" class="icon brands fa-github"><span class="label">GitHub</span></a></li>
          <li><a href="#" class="icon brands fa-instagram"><span class="label">Instagram</span></a></li>
          <li><a href="#" class="icon brands fa-linkedin-in"><span class="label">LinkedIn</span></a></li>
        </ul>
      </li>
    </ul>
  </section>
</div>
