import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Yakogo Studio Inc.';
  description = 'We do what we want!';
  imageUrl = 'https://assets.stickpng.com/images/5a4613e5d099a2ad03f9c995.png';
  constructor(
    private meta: Meta,
    @Inject(DOCUMENT) private dom: Document
  ) { }
  ngOnInit(): void {
    this.meta.addTags([
      {
        name: 'twitter:card',
        content: 'summary_large_image'
      },
      {
        name: 'twitter:title',
        content: this.title
      },
      {
        name: 'twitter:description',
        content: this.description
      },
      {
        name: 'twitter:image',
        content: this.imageUrl
      }
    ]);
    this.meta.addTags([
      {
        name: 'og:type',
        content: 'website'
      },
      {
        name: 'og:title',
        content: this.title
      },
      {
        name: 'og:description',
        content: this.description
      },
      {
        name: 'og:image',
        content: this.imageUrl
      }
    ]);
  }
  setCanonicalURL(url?: string) {
    const canURL = url == undefined ? this.dom.URL : url;
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);
  }
}
