import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ITeamItem } from 'src/app/interfaces/team-item';

@Injectable({
  providedIn: 'root'
})
export class TeamService {
  people: ITeamItem[] = [
    {
      name: 'Serhii',
      imageUrl: 'https://lumiere-a.akamaihd.net/v1/images/ct_iceage_sid_21464_10f2d363.jpeg',
      description: 'Founder, Developer',
      story: 'I am the founder and developer of this project.',
      externalUrl: '#',
      mainContact: {
        displayName: 'Serhii',
        phone: '+38(050)506-9324',
        email: 'yakogo.kh.ua@gmail.com',
        location: 'Ukraine, Poltava'
      },
      skills: [
        'HTML',
        'CSS',
        'JavaScript',
        'TypeScript',
        'MySQL',
        'PHP',
        'Drupal',
        'JSON:API',
        'REST',
        'NodeJS',
        'NestJS',
        'Angular'
      ],
      features: [
        {
          name: 'Angular',
          description: 'I use Angular to create front-end apps.<div class="text-white"> HTML5, CSS3, Bootstrap, Angular Material</div>',
          logoUrl: 'https://static-00.iconduck.com/assets.00/angular-icon-1931x2048-bef1f3lm.png'
        },
        {
          name: 'Drupal 10',
          description: 'Drupal 10 goes for solid backend.<div class="text-white">JSON:API, REST, JWT</div>',
          logoUrl: 'https://cdn-icons-png.flaticon.com/512/889/889096.png'
        },
        {
          name: 'NestJS',
          description: 'I use middleware as middleware for complex projects.<div class="text-white">My projects work with Drupal JSON:API</div>',
          logoUrl: 'assets/images/nestjs-logo.png'
        },
        {
          name: 'NodeJS',
          description: 'I NodeJS to deliver server-driven events.<div class="text-white">Express, Socket.io</div>',
          logoUrl: 'assets/images/nodejs-logo.svg'
        }

      ],
      projects: [
        {
          name: 'Sunrise Logistics',
          description: 'Website for a transport and logistics company.',
          position: 'Full-stack developer',
          duties: 'Set up ubuntu server, install Drupal 10 with modules, create a theme, technical support.',
          imageUrl: 'assets/images/projects/sunrise.png',
          socials: [
            {faIcon: 'fab fa-twitter', url: 'https://twitter.com/SunriseLogUA'},
            {faIcon: 'fab fa-facebook-f', url: 'https://www.facebook.com/SunriseLogisticsUA'},
            {faIcon: 'fab fa-instagram ', url: 'https://www.instagram.com/sunriselogistics_ua'},
          ],
          primaryColor: '#DA4D1D',
          websiteUrl: 'https://sunriselog.com.ua/'
        },
        {
          name: 'Chatnovel club',
          description: 'Text roleplaying portal. Currently under development.',
          position: 'Co-founder, Developer',
          duties: 'Host, maintain, proof concept, create data storage, create back-end application',
          imageUrl: 'assets/images/projects/chatnovel.png',
          isActive: true,
          websiteUrl: 'https://chatnovel.club/'
        },
        {
          name: 'insparkl',
          description: 'This project is out of date.',
          position: 'Database developer',
          duties: 'Creating and optimizing queries to database, documenting PHP backend',
          imageUrl: 'assets/images/projects/insparkl.png',
          isActive: true,
          primaryColor: '#5A5A5A',
          socials: [
            {faIcon: 'fab fa-twitter', url: 'https://twitter.com/insparkl'},
            {faIcon: 'fab fa-facebook-f', url: 'https://www.facebook.com/inSparkl'},
          ],
        },

      ],
      languages: ['Ukrainian', 'English']
    },
    {
      name: 'ThunderFoxy',
      mainContact: {
        displayName: 'ThunderFoxy',
        phone: '-- phone placeholder',
        email: '-- email placeholder',
        location: '-- locaction placeholder'
      },
      imageUrl: 'https://pbs.twimg.com/profile_images/1440677501336899594/modW03i6_400x400.jpg',
      description: 'Creative director, Designer, PR-manager',
      externalUrl: '#',
      skills: ['EPIC'],
      features: [
        {
          name: 'Angular',
          description: 'I use Angular to create front-end apps.<div class="text-white"> HTML5, CSS3, Bootstrap, Angular Material</div>',
          logoUrl: 'https://static-00.iconduck.com/assets.00/angular-icon-1931x2048-bef1f3lm.png'
        },
        {
          name: 'Drupal 10',
          description: 'Drupal 10 goes for solid backend.<div class="text-white">JSON:API, REST, JWT</div>',
          logoUrl: 'https://cdn-icons-png.flaticon.com/512/889/889096.png'
        },
        {
          name: 'NestJS',
          description: 'I use middleware as middleware for complex projects.<div class="text-white">My projects work with Drupal JSON:API</div>',
          logoUrl: 'assets/images/nestjs-logo.png'
        },
        {
          name: 'NodeJS',
          description: 'I NodeJS to deliver server-driven events.<div class="text-white">Express, Socket.io</div>',
          logoUrl: 'assets/images/nodejs-logo.svg'
        }

      ],
      projects: [
        {
          name: 'Chatnovel club',
          description: 'Text roleplaying portal. Currently under development.',
          position: 'Founder, Designer, Author',
          duties: 'Idea creation, proof of concept, overall design',
          imageUrl: 'assets/images/projects/chatnovel.png',
          isActive: true,
          websiteUrl: 'https://chatnovel.club/'
        },
      ],

      languages: ['English', 'Spanish', 'French', 'Japanese']
    }
  ];
  constructor(private router: Router) { }
  getTeamMate(name: string): ITeamItem[] {
    const found = Object.values(this.people).filter(el => el.name == name);
    return found;
  }
  getTeam(): ITeamItem[] {
    return this.people;
  }
}
