import { Component, Input } from '@angular/core';

import { IProject } from 'src/app/interfaces/project';
import { ISocial } from 'src/app/interfaces/socials';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent {
  @Input() project: IProject = <IProject>{};
  click(social: ISocial): void {
    window.open(social.url, "_blank");
  }
  goToProject(){
    if (this.project.websiteUrl) {
      window.open(this.project.websiteUrl, "_blank");
    }
  }

}
