import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IMenuItem } from 'src/app/interfaces/menu-item';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  @Input() menu: IMenuItem[] = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }
  ngOnInit() {
    this.route.fragment.subscribe((fragment) => {
      if (fragment) {
        this.setActive(fragment);
      } else {
        this.setActive('intro');
      }
    });
  }
  setActive(fragment: string) {
    this.menu.forEach(element => {
      element.isActive = `#${fragment}` == element.href;
    });
  }
  click(item: IMenuItem): void {
    console.log(this.router.url.split('#')[0], item.href.replace('#',''));
    item.isActive = true;
    this.router.navigate( [ this.router.url.split('#')[0] ], { fragment: item.href.replace('#','') } );
  }
  getHref(href: string): string {
    return `${this.router.url.split('#')[0]}${href}`;
  }

}
