import { Component } from '@angular/core';

import { IFeedback } from 'src/app/interfaces/feedback';
import { FeedbackService } from 'src/app/services/feedback.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent {
  name: string = '';
  feedback: IFeedback = <IFeedback>{};
  validateEmail: boolean = true;
  constructor(private feedbackService: FeedbackService) {}
  send() {
    this.feedbackService.send(
      `name: ${this.feedback.name} <${this.feedback.email}>\nmessage: ${this.feedback.description}`
    ).subscribe(data => {});
  }
}
