import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { catchError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(
    private http: HttpClient
  ) { }
  send(message: string) {
    const formData = new FormData();
    formData.append('message', message);
    return this.http.post('https://bc.17oz.xyz/viber/send.php', formData);
    //this.httpClient.post<any>('https://bc.17oz.xyz/viber/send.php', formData);
  }
  handleError(arg0: string, hero: any): (err: any, caught: import("rxjs").Observable<ArrayBuffer>) => import("rxjs").ObservableInput<any> {
    throw new Error('Method not implemented.');
  }
}
