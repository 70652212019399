import { Component, Input } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ITeamItem } from 'src/app/interfaces/team-item';
import { TeamService } from 'src/app/services/team.service';

@Component({
  selector: 'app-mate',
  templateUrl: './mate.component.html',
  styleUrls: ['./mate.component.scss']
})
export class MateComponent {
  @Input() name = '';
  @Input() mate: ITeamItem = <ITeamItem>{};
  constructor(
    private teamService: TeamService,
    private router: Router,
    private meta: Meta
  ) {}
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    const mate = this.teamService.getTeamMate(this.name).pop();
    if(!mate) {
      console.log('404.NOT FOUND');
    } else {
      this.mate = mate;
    }
  }
  ngAfterContentChecked(): void {
    //Called after every check of the component's or directive's content.
    //Add 'implements AfterContentChecked' to the class.
    this.meta.updateTag({ name: 'twitter:title',
    content: this.mate.name });
    let description = this.mate.description;
    if (this.mate.story) {
      description = this.mate.story;
    }
    this.meta.updateTag({ name: 'twitter:description',
    content: description });
    this.meta.updateTag({ name: 'twitter:image',
    content: this.mate.imageUrl });
  }
  navigateHome() {
    this.router.navigate([''],  { fragment: 'intro' });
  }
}
