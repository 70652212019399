import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent {
  title: string = 'Yakogo Studio Inc.';
  description: string = 'We do what we want! <br>Our team is never forced to do anything, that is how we are always inspired.';
  cardImageUrl: string = 'assets/favicon.png';
  constructor(
    private router: Router,
    private meta: Meta
  ) { }
  ngOnInit(): void {

  }
  click() {
    this.router.navigate(['/'], { fragment: 'one' });
  }

}
