import { Component, Input } from '@angular/core';
import { IFeature } from 'src/app/interfaces/feature';

@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.scss']
})
export class FeatureComponent {
  @Input() feature: IFeature = <IFeature>{};
}
