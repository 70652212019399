<div class="container">

  <div class="card">
    <h2>{{project.name}}</h2>
    <i *ngIf="project.websiteUrl" class="fas fa-arrow-right" (click)="goToProject()"></i>
    <p>{{project.position}}</p>
    <div class="pic" (click)="goToProject()" style="background-image: url('{{project.imageUrl}}');filter: brightness(40%);"></div>
    <ul>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
    <div class="social">
      <i *ngFor="let social of project.socials" class="{{social.faIcon}}" (click)="click(social)"></i>
    </div>
    <button class="more" style="background-color: {{project.primaryColor}}">
    </button>
  </div>
</div>
