<a href="/" class="home-button">Home</a>
<div id="wrapper">

  <!-- Intro -->
  <section id="intro" class="wrapper style1 fullscreen fade-up">

    <app-mate-photo [mate]="mate"></app-mate-photo>
    <div class="inner">

      <h1 class="title">Hello, my name is <span>{{mate.name}}</span></h1>
      <p [innerHTML]="mate.story"></p>
      <div>
        I speak <ul class="coma-list"><li *ngFor="let language of mate.languages">{{language}}</li></ul>
      </div>

      <div>
        My skills are <ul class="coma-list"><li *ngFor="let skill of mate.skills">{{skill}}</li></ul>
      </div>

    </div>
  </section>


  <!-- One -->
  <section id="one" class="wrapper style2 spotlights fullscreen anchor_tags">
    <div class="inner">
      <h2 class="page-title">What I can</h2>
      <app-feature *ngFor="let feature of mate.features" [feature]="feature"></app-feature>
    </div>
  </section>

  <!-- Two -->
  <section id="two" class="wrapper style3 fullscreen fade-up anchor_tags">
    <h2 class="page-title">What I did</h2>
    <div class="inner conainer">

      <app-project *ngFor="let project of mate.projects" [project]="project"></app-project>
    </div>
  </section>

  <!-- Three -->
  <section id="three" class="wrapper style1 fullscreen fade-up anchor_tags" *ngIf="mate.mainContact">
    <h2 class="page-title">Contacts</h2>
    <div class="inner">
      <app-contacts [contact]="mate.mainContact"></app-contacts>
    </div>
  </section>
</div>
